$(function () {
    var table = $('.results-table').DataTable({
        dom: "t",
        paging: false,
        bSort: false,
        autoWidth: true,
        select: true,
        scrollX: true,
        fixedHeader: true,
        responsive: {
            details: {
                type: 'none',
                target: ''
            }
        },
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
        columnDefs: [
            {responsivePriority: 1, targets: [0, 2, 6]},
            {responsivePriority: 999, targets: [1, 3, 4, 5]},
            {"targets": [1, 2, 3], "searchable": true},
            {"targets": "_all", "searchable": false}
        ]
    }).on('init', function () {
        $('#search-button').on("click", function () {
            table.search($('#search-query').val()).draw();
        });
        $('#search-query').on("keyup", function () {
            table.search($(this).val()).draw();
        });
    });
});
