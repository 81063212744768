$(function () {
    var splits_table = $('.splits-table').DataTable({
        dom: "t",
        paging: false,
        bSort: false,
        autoWidth: true,
        select: true,
        scrollX: true,
        fixedHeader: true,
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
        columnDefs: [
            {"targets": [1, 2, 3], "searchable": true},
            {"targets": "_all", "searchable": false}
        ]
    }).on('init', function () {
        $('#search-query').on("keyup", function () {
            splits_table.search($(this).val()).draw();
        });
    });
});
