window._ = require('lodash');
import * as bootstrap from 'bootstrap';

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.bootstrap = bootstrap;

} catch (e) {}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

