require('flatpickr');
import {Bulgarian} from "flatpickr/dist/l10n/bg"
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";

export function initForms() {
    $('form.ajax').ajaxForm({
        delegation: false,
        beforeSubmit: function (formData, jqForm) {
            $(jqForm[0])
                .find('.error.help-block')
                .remove();
            $(jqForm[0]).find('.has-error')
                .removeClass('has-error');
            var $submitButton = jqForm.find(':submit');
            toggleSubmitDisabled($submitButton);
        },
        uploadProgress: function (event, position, total, percentComplete) {
            $('.uploadProgress').show().html('Uploading Images - ' + percentComplete + '% Complete...    ');
        },
        error: function (data, statusText, xhr, $form) {
            // Form validation error.
            if (422 == data.status) {
                processFormErrors($form, $.parseJSON(data.responseText));
                return;
            }

            if (423 == data.status) {
                showMessage(data.responseJSON.message, "error");
            } else {
                showMessage('Whoops!, it looks like the server returned an error.', "error");
            }

            var $submitButton = $form.find(':submit');
            toggleSubmitDisabled($submitButton);

            $('.uploadProgress').hide();
        },
        success: function (data, statusText, xhr, $form) {
            switch (data.status) {
                case 'success':
                    if ($form.hasClass('reset')) {
                        $form.resetForm();
                    }

                    if ($form.hasClass('closeModalAfter')) {
                        $('.modal, .modal-backdrop').fadeOut().remove();
                    }

                    var $submitButton = $form.find(':submit');
                    toggleSubmitDisabled($submitButton);

                    if (typeof data.message !== 'undefined') {
                        showMessage(data.message, "success");
                    }

                    if (typeof data.runThis !== 'undefined') {
                        eval(data.runThis);
                    }

                    if (typeof data.redirectUrl !== 'undefined') {
                        window.location.href = data.redirectUrl;
                    }

                    break;

                case 'error':
                    processFormErrors($form, data.messages);
                    break;

                default:
                    break;
            }

            $('.uploadProgress').hide();
        },
        dataType: 'json'
    });
}

export function setSortableTable() {
    $('.sortable').sortable({
        handle: '.sortHanlde',
        forcePlaceholderSize: true,
        placeholder: '<tr><td class="bg-info" colspan="6">&nbsp;</td></tr>'
    }).bind('sortupdate', function (e, ui) {
        let url = $(this).data('url');
        let data = $('.sortable tr').map(function () {
            return $(this).data('row-id');
        }).get();

        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            data: {data: data},
            success: function (data) {
                showMessage(data.message)
            },
            error: function (data) {
                console.log(data);
            }
        });
    });
}

export function setSummerNote(is_public = false) {
    const options = {
        height: 300,
        maximumImageFileSize: 2000 * 1024,
        callbacks: {
            onImageUpload: function (image) {
                uploadImage(image[0]);
            },
            onImageUploadError: function (msg) {
                console.log(msg + ' (2 MB)');
            }
        }
    };
    if (is_public) {
        options.toolbar = [
            ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['color', ['color']],
        ];
    }
    $('.summernote').summernote(options);
}

export function toggleSubmitDisabled($submitButton) {
    if ($submitButton.hasClass('disabled')) {
        $submitButton.attr('disabled', false)
            .removeClass('disabled')
            .val($submitButton.data('original-text'));
        return;
    }

    $submitButton.data('original-text', $submitButton.val())
        .attr('disabled', true)
        .addClass('disabled')
        .val('Working...');
}

export function DotArrayToJs(str) {
    var splittedStr = str.split('.');
    return splittedStr.length === 1 ? str : (splittedStr[0] + '[' + splittedStr.splice(1).join('][') + ']');
}

export function setTranslatableEvent() {
    $('.translatable-link').on('click', function (e) {
        e.preventDefault();
        let key = $(this).data('for');
        let lang = $(this).data('lang');

        $(`.translatable-field-${key}`).addClass('d-none');
        $(`.btn-translatable-${key}`).addClass('d-none');

        $(`#input-${key}-${lang}`).removeClass('d-none');
        $(`#btn-translatable-${key}-${lang}`).removeClass('d-none');
    });
}

export function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(uri);
    }
}

export function setDatetimePickerButtonClickListener() {
    var lang = $('html').attr('lang');

    $('.openCalendar').on("click", function () {
        $(this).closest('.input-group').find('.hasDatepicker').flatpickr({
            disableMobile: true,
            locale: lang === "bg" ? Bulgarian : undefined,
            plugins: [new ConfirmDatePlugin({
                    confirmIcon: "<i class='icofont-check'></i>",
                    confirmText: "OK ",
                    showAlways: false,
                    theme: "dar",
                }
            )]
        }).open();
    });
}

export function setDatetimePicker() {
    var lang = $('html').attr('lang');

    $(".hasDatepicker").attr('autocomplete', 'off').flatpickr({
        disableMobile: true,
        locale: lang === "bg" ? Bulgarian : undefined,
        plugins: [new ConfirmDatePlugin({
            confirmIcon: "<i class='fa fa-check'></i>",
            confirmText: "OK ",
            showAlways: false,
            theme: "light",
        })]
    });
}

export function uploadImage(image) {
    var data = new FormData();
    data.append("image", image);
    $.ajax({
        url: "/upload",
        cache: false,
        contentType: false,
        processData: false,
        data: data,
        type: "POST",
        success: function (filename) {
            var image = $('<img>').attr('src', filename).addClass("img-fluid");
            $('.summernote').summernote("insertNode", image[0]);
        },
        error: function (data) {
            var responce = $.parseJSON(data.responseText);
            showMessage(responce.errors.image[0], "error");
        }
    });
}

export function bibsTrigger() {
    var checked = $('#is_bibs').is(":checked");

    $("#bib_from").attr("required", checked);
    $("#bib_to").attr("required", checked);

    if (checked) {
        $(".bibs").show(200);
    } else {
        $(".bibs").hide(200);
    }
}

export function processFormErrors($form, errors) {
    $.each(errors, function (index, error) {
        $(`input[name^="${DotArrayToJs(index)}"], select[name^="${DotArrayToJs(index)}"],
        textarea[name^="${DotArrayToJs(index)}"]`).each(function () {
            if ($(this).prop('type') === 'file') {
                $('#input-' + $(this).prop('name')).append('<div class="help-block error">' + error + '</div>')
                    .parent()
                    .addClass('has-error');
            } else if ($(this).hasClass('hasDatepicker')) {
                $(this).siblings('.invalid-feedback').remove(); // Премахваме предишните съобщения за грешка
                $(this).siblings('.input-group-text').after('<div class="invalid-feedback">' + error + '</div>');
                $(this).addClass('is-invalid');
            } else {
                $(this).parent().append('<div class="help-block error">' + error + '</div>')
                    .addClass('has-error');
            }
        });
    });

    var $submitButton = $form.find(':submit');
    toggleSubmitDisabled($submitButton);
}

export function showMessage(message, status) {
    $.notify(message, {
        globalPosition: 'top right',
        className: status || 'info'
    });
}

export function initLicenseChange() {
    $('.categories-select, .ticket_holder_category').on('change', function () {
        let $selectedOption = $(this).find(':selected');
        let has_license = $selectedOption.data('license');
        let licenseId = $selectedOption.data('license-id');
        let $licenseElement = $(`#${licenseId}`);
        $licenseElement.prop('disabled', !has_license).parent('div').toggleClass('d-none', !has_license);
    })
}

export function clearFormErrors($form) {
    $($form)
        .find('.error.help-block')
        .remove();
    $($form).find(':input')
        .parent()
        .removeClass('has-error');
    $($form).find(':input')
        .parent().parent()
        .removeClass('has-error');
}

export function setShareButton() {
    $('#fb-share-button').on('click', function () {
        var url = $(this).data('url');
        window.open(
            'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
            'facebook-share-dialog',
            'width=800,height=600'
        );
        return false;
    });

    $('#tw-share-button').on('click', function () {
        var url = $(this).data('url');
        window.open('https://www.twitter.com/share?url=' + url,
            'twitter-share-dialog',
            'width=800,height=600'
        );
        return false;
    });
}

export function removeOption(removeBtn, check = true){
    let btn = $(removeBtn);
    let tbody = btn.parents('tbody');

    if (!check || tbody.find('tr').length > 1) {
        btn.parents('tr').remove();
    } else {
        alert(lang("at_least_one_option"));
    }
}
