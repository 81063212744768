$(function () {
    if ($('#splits-table').length) {
        let splits_table = $('#splits-table').DataTable({
            dom: "tr",
            paging: false,
            bSort: false,
            autoWidth: true,
            select: true,
            scrollX: true,
            fixedHeader: true,
            processing: true,
            language: {
                url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
            },
            columnDefs: [
                {"targets": [1, 2, 3], "searchable": true},
                {"targets": "_all", "searchable": false}
            ],
            initComplete: function () {
                splits_table.settings()[0].oFeatures.bProcessing = false;
            }
        }).on('init', function () {
            $('#search-button').on("click", function () {
                splits_table.search($('#search-query').val()).draw();
            });
            $('#search-query').on("keyup", function () {
                splits_table.search($(this).val()).draw();
            });
        });
    }
});
