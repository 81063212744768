$(function () {
    registrations = $('.registrations-table').DataTable({
        dom: "ft",
        paging: false,
        select: true,
        autoWidth: true,
        bSort: false,
        scrollX: true,
        // fixedHeader: true,
        // Бъгнат е плъгина и страницата подскаача при повече от една таблица на страница
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
        columnDefs: [
            {responsivePriority: 1, targets: [1, 2, 4]},
            {responsivePriority: 999, targets: [0, 3]},
        ]
    });
});
