$(function () {
    $('#events').DataTable({
        paging: true,
        pageLength: 25,
        ordering: false,
        info: false,
        responsive: true,
        fixedHeader: true,
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
    });
});
