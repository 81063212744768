$(function () {
    let table = $('#events').DataTable({
        'dom': "<'row'<'col-sm-12'>>" +
            "<'row'<'col-sm-12 col-md-6'><'col-sm-12 col-md-6'>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-md-12'p>>",
        paging: true,
        pageLength: 25,
        ordering: false,
        info: false,
        responsive: true,
        fixedHeader: true,
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
    }).on('init', function () {
        $('#search-button').on("click", function () {
            table.search($('#search-query').val()).draw();
        });
        $('#search-query').on("keyup", function () {
            table.search($(this).val()).draw();
        });
    });
});
