$(document).ready(function () {
    let registrations = $('.registrations-table').DataTable({
        dom: "ft",
        paging: false,
        select: true,
        autoWidth: true,
        bSort: false,
        scrollX: true,
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
        columnDefs: [
            {responsivePriority: 1, targets: [1, 2, 4]},
            {responsivePriority: 999, targets: [0, 3]},
        ]
    });
    // За embed
    $("#searchInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();

        $(".pl-tbody .row").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
    });

    document.querySelectorAll('a[href^="#event-"]').forEach(function(link) {
        link.addEventListener('click', function(e) {
            e.preventDefault(); // Спира стандартното поведение на линка
            var targetId = this.getAttribute('href').substring(1); // Получава ID на елемента
            var targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        });
    });
});
