$(function () {
    let ctxArray = document.querySelectorAll('[id^=profileChart]');
    ctxArray.forEach(function (ctx) {
        if (ctx !== null && ctx !== undefined) {
            ctx = ctx.getContext('2d');
            //adding custom chart type
            Chart.defaults.multicolorLine = Chart.defaults.line;
            Chart.controllers.multicolorLine = Chart.controllers.line.extend({
                draw: function (ease) {
                    let
                        startIndex = 0,
                        meta = this.getMeta(),
                        points = meta.data || [],
                        colors = this.getDataset().colors,
                        area = this.chart.chartArea,
                        originalDatasets = meta.dataset._children
                            .filter(function (data) {
                                return !isNaN(data._view.y);
                            });

                    function _setColor(newColor, meta) {
                        meta.dataset._view.borderColor = newColor;
                    }

                    if (!colors) {
                        Chart.controllers.line.prototype.draw.call(this, ease);
                        return;
                    }

                    for (let i = 2; i <= colors.length; i++) {
                        if (colors[i - 1] !== colors[i]) {
                            _setColor(colors[i - 1], meta);
                            meta.dataset._children = originalDatasets.slice(startIndex, i);
                            meta.dataset.draw();
                            startIndex = i - 1;
                        }
                    }

                    meta.dataset._children = originalDatasets.slice(startIndex);
                    meta.dataset.draw();
                    meta.dataset._children = originalDatasets;

                    points.forEach(function (point) {
                        point.draw(area);
                    });
                }
            });
        }
    });

    Chart.plugins.register({
        afterDatasetsDraw: function (chartInstance) {
            if (chartInstance.config.options.pointReferenceLetters || chartInstance.config.options.pointReferenceLetters.display) {
                let references = chartInstance.config.options.pointReferenceLetters.references || [];
                let helpers = Chart.helpers;
                let ctx = chartInstance.chart.ctx;
                let fontColor = helpers.getValueOrDefault(chartInstance.config.options.pointReferenceLetters.fontColor, chartInstance.config.options.defaultFontColor);

                // render the value of the chart above the bar
                ctx.font = Chart.helpers.fontString(10, 'bold', Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                let checkpoints = [];

                chartInstance.data.datasets.forEach(function (dataset, dsindex) {
                    for (let i = 0; i < dataset.data.length; i++) {
                        ctx.beginPath();
                        let model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;

                        ctx.strokeStyle = dataset.colors[i];
                        ctx.moveTo(model.x, model.y);
                        ctx.lineTo(model.x, chartInstance.height);
                        ctx.stroke();

                        let refPoint = references.find(function (e) {
                            if (i > 0 && e.graph == dsindex && e.distance > dataset.data[i - 1].x && e.distance <= dataset.data[i].x) {
                                return true
                            }
                        });

                        if (refPoint) {
                            let lines = refPoint.reference.split('\n');
                            ctx.moveTo(model.x, model.y);
                            ctx.strokeStyle = fontColor;
                            ctx.lineTo(model.x, model.y - 35);
                            ctx.stroke();

                            for (let j = 0; j < lines.length; j++) {
                                checkpoints.push({
                                    'lines': lines[j],
                                    'x': model.x,
                                    'y': model.y,
                                    'j': model.y - 50 + j * 15
                                })
                            }
                        }
                    }
                });

                checkpoints.forEach(function (data) {
                    ctx.fillStyle = fontColor;
                    ctx.fillText(data.lines, data.x, data.j);

                    ctx.moveTo(data.x, data.j);
                    ctx.arc(data.x, data.y, 4, 0, Math.PI * 2);
                    ctx.fill();
                });
            }
        }
    });
});
