$(function () {
    $('#table-organisers').DataTable({
        'dom': "<'row'<'col-sm-12'>>" +
            "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-md-12'p>>",
        language: {
            url: "/assets/javascript/language/" + $('html').attr('lang') + "/language.json"
        },
        stateSave: true,
        pageLength: 25,
        autoWidth: true,
        responsive: true,
        order: [],
    });
});
